import { Component, Vue } from 'vue-property-decorator';
import {
    isImaAccount,
    isMatmutAccount,
    isImaSanteAccount,
    isImaMedicalAccount,
    isHesAccount,
} from '@/helpers/account';

@Component
export default class AccountMixin extends Vue {
    get isImaAccount(): boolean {
        return isImaAccount(this.user);
    }

    get isMatmutAccount(): boolean {
        return isMatmutAccount(this.user);
    }

    get isImaSanteAccount(): boolean {
        return isImaSanteAccount(this.user);
    }

    get isImaMedicalAccount(): boolean {
        return isImaMedicalAccount(this.user);
    }

    get isOtherAccount(): boolean {
        return Boolean(!this.isImaAccount && !this.isMatmutAccount && !this.isImaSanteAccount && !this.isImaMedicalAccount);
    }

    get isHesAccount(): boolean {
        return isHesAccount(this.user);
    }
};
