import { User } from '@/types/User';

function isImaSanteAccount(currentUser: User): boolean {
    return currentUser.account_name === 'ima_sante';
}

function isImaMedicalAccount(currentUser: User): boolean {
    return currentUser.account_name === 'ima_medical';
}

function isImaAccount(currentUser: User): boolean {
    return currentUser.account_name === 'ima';
}

function isMatmutAccount(currentUser: User): boolean {
    return currentUser.account_name === 'matmut';
}

function isHesAccount(currentUser: User): boolean {
    return currentUser.account_name === 'hes';
}

export {
    isImaAccount,
    isMatmutAccount,
    isImaSanteAccount,
    isImaMedicalAccount,
    isHesAccount,
};
