<template>
<div class='h-width-100p h-height-100p h-flex'>
    <div v-if='showCalendar'
         ref='leftBar'
         class='b-calendar-page__left-bar'
         :class='{ "b-over-fixed-background": isFixedBg }'>
        <LeftBarMenu v-if='isMenuOpen'></LeftBarMenu>
        <div class='b-calendar-page__calendar_wrapper'>
            <FwBurgerIcon
                class='b-calendar-page__burger'
                :size='24'
                :isActive='isMenuOpen'
                :color='isMenuOpen ? "#637898" : "#213F6B"'
                @toggle='isMenuOpen = !isMenuOpen'>
            </FwBurgerIcon>

            <div class='b-calendar-page__logo'>
                <img :src='requireImage("logo_calizy_pro.svg")'>
            </div>

            <CalendarDateNavigation
                v-if='date'
                :date='date'
                :menu='menu'
                :activeId='activeId'
                @changeCalendarTypeId='changeCalendarTypeId'
                @changeDay='changeDay'>
            </CalendarDateNavigation>

            <CalendarDatePicker
                :date='date'
                :eventsSlots='filteredEventsSlots'
                @changeCalendarMonth='changeCalendarMonth'
                @updateDates='updateDatesForce'
                @showChoosePopup='showChoosePopupHandler'>
            </CalendarDatePicker>
        </div>
    </div>
    <div class='b-calendar-page'>
        <FwSpinLoader
            v-if='datesLoading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='b-global-loading b-global-loading--transparent'
            :isActive='datesLoading'
            className='h-p-20'>
        </FwSpinLoader>

        <FwSpinLoader
            v-if='pageLoading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='b-global-loading'
            :isActive='pageLoading'
            className='h-p-20'>
        </FwSpinLoader>
        <div v-else>
            <NoEventPreviewPage
                v-if='!pageLoading && !datesLoading && events.length === 0 && showNoEventPreview && !isEventFirstInitialExist'
                @closeNoEventPreview='closeNoEventPreview'
                @createFirstEventPopup='createFirstEventPopup'>
            </NoEventPreviewPage>
            <FwHorizontalScroll
                v-if='showUsersList'
                :isPageLoaded='true'
                :style='scrollStyle'
                slotWrapperClass='h-flex h-flex-dir-column h-height-auto-force'
                iconClass='h-opacity-1'
                newClass='fw-arrow-big-circle'
                newClassModifier='fw-arrow-big-circle--middle-size'
                :arrowBgWidth='null'
                colorIcon='#fff'
                btnLocation='between'>
                <template #content>
                    <UserList
                        v-if='!pageLoading'
                        class='b-user-list__main--scroll-list'
                        :users='usersDataByKind'
                        :style='userListStyle'
                        :userFilterIds='userFilterIds'
                        @click='addActiveUser'>
                    </UserList>
                </template>
            </FwHorizontalScroll>

            <div v-else class='h-mv-30'>
                <h2 class='b-appointment-main__title h-width-100p h-text-center'>
                    {{ $t(currentMonthName) }} {{ currentYear }}
                </h2>
            </div>

            <FwHorizontalScroll
                :isPageLoaded='true'
                :style='scrollStyle'
                slotWrapperClass='h-flex h-flex-dir-column'
                iconClass='h-opacity-1'
                newClass='fw-arrow-big-circle'
                wrapperClass='b-calendar-page__corner-fix'
                :arrowBgWidth='null'
                colorIcon='#fff'
                :arrowKey='arrowKey'
                btnLocation='between'>
                <template #content>
                    <div class='b-calendar-page__body'>
                        <PlanTable
                            v-if='isWeekType && dates.length && !datesLoading'
                            :dates='dates'
                            :events='events'
                            :users='usersDataByKind'
                            :userFilterIds='userFilterIds'
                            :type='$route.name'
                            :updateKey='updateKey'
                            :lunchBreakMap='lunchBreakMap'
                            :whBreakMap='whBreakMap'
                            @openChoosePopup='openChoosePopup'>
                        </PlanTable>
                        <PlanTableMonth
                            v-if='isMonthType && dates.length && !datesLoading'
                            :dates='dates'
                            :events='events'
                            :users='usersDataByKind'
                            :date='calendarDate'
                            :userFilterIds='userFilterIds'
                            :type='$route.name'
                            :updateKey='updateKey'
                            :firstDayOfWeek='firstDayOfWeek'
                            :calendarYear='calendarYear'
                            :calendarMonth='calendarMonth'
                            @openChoosePopup='openChoosePopup'>
                        </PlanTableMonth>
                    </div>
                </template>
            </FwHorizontalScroll>
            <div v-if='!isDesktop()'
                 class='b-calendar-page__show-button h-flex-center'>
                <FwButton
                    class='h-mv-5'
                    borderRadiusType='small-border'
                    @click='showCalendar = !showCalendar'>
                    Show calendar
                </FwButton>
            </div>
        </div>
    </div>
    <ChooseEventPopup
        v-if='showChoosePopup'
        @close='showChoosePopup = false'
        @openAddPopup='openAddPopup'>
    </ChooseEventPopup>
    <AddOrEditEventPopup
        v-if='showAddPopup'
        :eventType='eventType'
        :event='addEventData'
        :loading='loading'
        :dates='dates'
        :users='usersDataByKind'
        :eventUserId='addEventUserId'
        :date='existingDate'
        :originValue='originValue'
        :lunchBreakMap='lunchBreakMap'
        :whBreakMap='whBreakMap'
        @openDeletePopup='showDeletePopup = true'
        @updateAllData='updateAllData'
        @updateDate='updateDate'
        @close='closeAddOrEditEventPopup'>
    </AddOrEditEventPopup>
    <DeletePopup
        v-if='showDeletePopup'
        :instance='addEventData'
        :isRequestSending='isDeleteRequestSending'
        @removeEvent='removeEvent'
        @close='showDeletePopup = false'>
    </DeletePopup>
    <InformationPopup
        v-if='isNoAccessPopup && eventDataToShow'
        :headerText='$t(`NO.ACCESS.CALENDAR`)'
        :bodyText='$t(`NO.ACCESS.CALENDAR.ONLY.ADMIN`)'
        :buttonText='$t(`POPUP.FINALY.CLOSE`)'
        @close='isNoAccessPopup = false'>
    </InformationPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DateMixin from '@/mixins/dateMixin';
import { MONTHS } from '@/helpers/dates';
import CalendarMixin from '@/mixins/calendarMixin';
import AccountMixin from '@/mixins/account';
import PermissionMixin from '@/mixins/permission';
import { EventsWebApi } from '@/api/event/EventsApi';
import { CalendarDatePicker } from '@/components/common/CalendarDatePicker';
import { UserList } from '@/components/common/UserList';
import { CalendarDateNavigation } from '@/components/common/CalendarDateNavigation';
import { PlanTable } from '@/components/common/PlanTable';
import { PlanTableMonth } from '@/components/common/PlanTableMonth';
import { LeftBarMenu } from '@/components/common/LeftBarMenu';
import { RoomListType, UserListType, userTableDataType, UserType } from '@/types/User';
import { ChooseEventPopup } from '@/components/popups/ChooseEventPopup';
import { AddOrEditEventPopup } from '@/components/popups/AddOrEditEventPopup';
import { InformationPopup } from '@/components/popups/InformationPopup';
import { EventType, LunchDaysTimeType, whDaysTimeType } from '@/types/Events/Base';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { CalendarInstanceType, CalendarReservedSlotsType } from '@/types/Events/CalendarInstanceType';
import { NoEventPreviewPage } from '@/components/simple/NoEventPreviewPage';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { CalendarDateNavigationMenuType } from '@/types/calendar';
import { SitesBranchDataType } from '@/types/Site';
import { SitesApi } from '@/api/sites/SitesApi';
import i18n from '@/locale';
import { AuthWebApi } from '@/api/auth/AuthApi';

const UserStore = namespace('UserStore');
const CommonStore = namespace('CommonStore');
const CalendarPageStore = namespace('CalendarPageStore');

@Component({
    components: {
        CalendarDatePicker,
        CalendarDateNavigation,
        PlanTable,
        UserList,
        LeftBarMenu,
        ChooseEventPopup,
        AddOrEditEventPopup,
        InformationPopup,
        DeletePopup,
        NoEventPreviewPage,
        PlanTableMonth,
    },
    refs: [`leftBar`],
})
export default class CalendarViewPage extends Mixins(DateMixin, CalendarMixin, PermissionMixin, AccountMixin) {
    @CalendarPageStore.State('events') events!: Array<CalendarInstanceType>;
    @CalendarPageStore.State('calendar_reserved_slots') eventsSlots!: Array<CalendarReservedSlotsType>;
    @CalendarPageStore.State('is_event_first_initial_exist') isEventFirstInitialExist!: boolean;
    @UserStore.Mutation('USERS_DATA') usersData!: Function;
    @CommonStore.Getter('isFixedBg') isFixedBg!: boolean;
    @CommonStore.Getter('hideFixedBgStatus') hideFixedBgStatus!: boolean;
    @CommonStore.Mutation('setFixedBgStatus') setFixedBgStatus!: Function;
    @CommonStore.Mutation('setHideBgStatus') setHideBgStatus!: Function;

    menu: Array<CalendarDateNavigationMenuType> = [
        {
            id: 1,
            title: i18n.tc('CALENDAR.WEEK.TITLE'),
            value: '',
            routeName: 'CalendarViewPage',
        },
        {
            id: 2,
            title: i18n.tc('CALENDAR.MONTH.TITLE'),
            value: '/month',
            disabled: true,
            icon: 'lock',
            routeName: 'CalendarViewPageMonth',
        },
        {
            id: 3,
            title: i18n.tc('CALENDAR.YEAR.TITLE'),
            value: '/year',
            disabled: true,
            icon: 'lock',
            routeName: 'CalendarViewPageYear',
        },
    ];

    lunchBreakMap: LunchDaysTimeType | any = {
        Monday: {
            monday_break_start: null,
            monday_break_end: null,
        },
        Tuesday: {
            tuesday_break_start: null,
            tuesday_break_end: null,
        },
        Wednesday: {
            wednesday_break_start: null,
            wednesday_break_end: null,
        },
        Thursday: {
            thursday_break_start: null,
            thursday_break_end: null,
        },
        Friday: {
            friday_break_start: null,
            friday_break_end: null,
        },
        Saturday: {
            saturday_break_start: null,
            saturday_break_end: null,
        },
        Sunday: {
            sunday_break_start: null,
            sunday_break_end: null,
        },
    };

    whBreakMap: whDaysTimeType | any = {
        Monday: {
            monday_start: null,
            monday_end: null,
        },
        Tuesday: {
            tuesday_start: null,
            tuesday_end: null,
        },
        Wednesday: {
            wednesday_start: null,
            wednesday_end: null,
        },
        Thursday: {
            thursday_start: null,
            thursday_end: null,
        },
        Friday: {
            friday_start: null,
            friday_end: null,
        },
        Saturday: {
            saturday_start: null,
            saturday_end: null,
        },
        Sunday: {
            sunday_start: null,
            sunday_end: null,
        },
    };

    showCalendar: boolean = this.isTablet() || this.isDesktop();
    date: Date | null = null;
    calendarDate: Date = new Date();
    dates: Array<Date> = [];
    datesInterval = 7;
    updateKey: number = 1;
    showChoosePopup: boolean = false;
    showDeletePopup: boolean = false;
    showNoEventPreview: boolean = true;
    showUsersList: boolean = false;
    showAddPopup: boolean = false;
    datesLoading: boolean = false;
    originValue: string = 'user';
    eventType: null | 'from_reserved_slot' | 'unavailability' = null;
    existingDate: Date | null = null;
    userFilter: Array<UserListType | RoomListType> = [];
    isMenuOpen: boolean = false;
    arrowKey: number = 1;
    firstDayOfWeek: number = 1;
    activeId: number = this.currentRouteValue ? this.currentRouteValue.id : 1;

    addEventData: EventType | null = null;
    addEventUserId: string | null = null;
    loading: boolean = false;
    pageLoading: boolean = false;
    isNoAccessPopup: boolean = false;
    users: Array<UserType> | Array<SitesBranchDataType> = [];
    eventDataToShow: CalendarInstanceType | null | undefined = null;
    $refs!: {
        leftBar: HTMLElement
    };

    get userFilterIds(): Array<string> {
        if (this.userFilter && this.userFilter.length) {
            return this.userFilter.map(item => item.id);
        }
        return ['0'];
    }

    get userListStyle() {
        return {
            opacity: this.datesLoading ? `0` : `1`,
            paddingLeft: this.isWeekType ? `105px` : `57px`,
        };
    }

    get scrollStyle() {
        if (this.$refs.leftBar && this.$el) {
            return {
                width: `${(this.$el as HTMLElement).offsetWidth - this.$refs.leftBar.offsetWidth}px`,
            };
        }
        return null;
    }

    get usersDataByKind() {
        return this.isUserKindAdvisor ? this.users : this.users;
    }

    get isYearType(): boolean {
        return this.$route.path === '/calendar/main/year';
    }

    get currentMonthName(): string {
        return this.date ? MONTHS[this.date.getMonth()] : '';
    }

    get currentYear(): string {
        return this.date ? `${this.date.getFullYear()}` : '';
    }

    get filteredEventsSlots(): Array<CalendarReservedSlotsType> {
        if (this.isAllUsersFilterActive) {
            return this.eventsSlots;
        }
        return this.eventsSlots.filter(event => this.userFilterIds.includes(event.user_id));
    }

    get isAllUsersFilterActive(): boolean {
        return this.userFilterIds.length === 1 && this.userFilterIds[0] === `0`;
    }

    get currentRouteValue() {
        return this.menu.find(item => this.$route.name === item.routeName);
    }

    updateDate(date: Date) {
        this.existingDate = date;
    }

    closeNoEventPreview() {
        this.showNoEventPreview = false;
    }

    openAddPopup({ origin, type }: { origin: string, type: 'from_reserved_slot' | 'unavailability' }) {
        this.originValue = origin || 'user';
        this.eventType = type;
        this.showChoosePopup = false;
        this.showAddPopup = true;
    }

    closeAddOrEditEventPopup() {
        this.showAddPopup = false;
        this.eventType = 'from_reserved_slot';
    }

    async addActiveUser(user: UserListType | RoomListType) {
        if (~this.userFilter.findIndex(item => item.id === user.id)) {
            const index = this.userFilter.findIndex(item => item.id === user.id);
            this.userFilter.splice(index, 1);
        } else {
            if (user.id !== '0' && this.userFilter.find(item => item.id === '0')) {
                const index = this.userFilter.findIndex(item => item.id === '0');
                this.userFilter.splice(index, 1);
            } else if (user.id === '0') {
                this.userFilter = [];
            }
            if (this.isImaSanteAccount || this.isImaMedicalAccount) {
                await this.getCalendarData(this.calendarDate, false, false, user.id);
            }

            this.userFilter.push(user);
        }
    }

    changeCalendarTypeId(route: CalendarDateNavigationMenuType) {
        const path = `/calendar/main${route.value}`;
        if (this.$route.path === path) return;
        this.$router.push(path);
        this.activeId = route.id;
        this.getCalendarData((this.date as Date));
    }

    userIsAdmin() {
        if (this.userPermissionOnlyAdmin || this.isUserKindAdvisor) {
            this.showUsersList = true;
        } else {
            this.showUsersList = false;
            this.addActiveUser(this.user);
        }
    }

    createDates(date: Date | null = null) {
        const dates = [];
        const localDate = this.getDayDateAtNight(date || this.date || new Date());
        for (let delta = 0; delta < this.datesInterval; delta++) {
            dates.push(this.getDate({ delta, date: localDate }));
        }
        this.dates = dates;
        this.date = this.dates[0];
    }

    updateDatesForce(date: Date) {
        this.updateDates(date, true);
    }

    async updateDates(date: Date, force: boolean = false) {
        if (this.isWeekType) {
            this.createDates(date);
            try {
                if (this.isImaSanteAccount || this.isImaMedicalAccount) {
                    this.userFilter = [this.user];
                    await this.getCalendarData(date, force, false, this.userId);
                } else {
                    await this.getCalendarData(date, force, false);
                }
            } catch ({ response }) {
                this.sentNotif(response);
            }
            this.calendarDate = date;
        } else if (this.isMonthType) {
            if (this.calendarMonth !== date.getMonth() + 1) {
                this.calendarMonth = date.getMonth() + 1;
            }

            try {
                await this.getCalendarData(date, force);
            } catch ({ response }) {
                this.sentNotif(response);
            }
            this.calendarDate = date;
        }
    }

    changeDay({ delta, date }: { delta: number, date: null | Date }) {
        this.updateDates(this.getDate({ delta, date: date || this.date }));
    }

    showChoosePopupHandler() {
        this.addEventData = null;
        this.addEventUserId = this.user.id;
        this.showChoosePopup = true;
    }

    handleResize() {
        this.windowWidth = window.innerWidth;
        this.showCalendar = this.windowWidth > 992;
    }

    createFirstEventPopup() {
        this.openChoosePopup({ data: null, user: this.user, date: new Date() });
    }

    async openChoosePopup({ data, user, date }: { data?: CalendarInstanceType | null, user: userTableDataType, date: Date }) {
        if (this.userPermissionOnlyAdmin || (!this.userPermissionOnlyAdmin && data && this.user.id === data.user_id) || !data) {
            this.addEventUserId = user ? user.id : null;
            if (data && data.id) {
                this.loading = true;
                this.showAddPopup = true;
                try {
                    const { event } = await EventsWebApi.getEvent(data.event_id);

                    if (data.is_recurrent) {
                        event.original_dt_start = event.dt_start;
                        event.original_dt_end = event.dt_end;
                        event.dt_end = data.dt_end;
                        event.dt_start = data.dt_start;
                    }

                    if (event && event.site_id) {
                        this.existingDate = new Date(event.dt_start);
                    }
                    this.addEventData = Object.assign(event, { instance_id: data.id });
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else {
                if (user.is_site) {
                    this.showAddPopup = true;
                } else {
                    this.showChoosePopup = true;
                }
                this.addEventData = null;
                this.existingDate = date;
            }
            this.loading = false;
        } else {
            this.eventDataToShow = data;
            this.isNoAccessPopup = true;
        }
    }

    async getWorkers() {
        try {
            const { data } = await WorkersWebApi.getTechniciansData({ per_page: 110, current_page: 1 });
            this.users = data.users;
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async getSiteById(id: string, site: string) {
        try {
            const response = await SitesApi.getSite(id, site);
            let usersToView = [];

            if (this.isUserKindAdvisor && this.userPermissionOnlyAdmin) {
                if (site === 'hubs') {
                    usersToView = response.hub.users;
                } else {
                    usersToView = response.site.users;
                }
            }

            let siteData = [];

            if (site === 'sites') {
                siteData = response.site.branch_rooms.map(({ ...rest }) => ({ is_site: true, auth_user_id: this.user.id, ...rest }));
            }

            this.users = [...usersToView, ...siteData];

            if (response.site.working_schedule_id) {
                const { working_schedule } = await SitesApi.getSiteWorkingHours(response.site.working_schedule_id);
                Object.keys(this.lunchBreakMap).forEach(day => {
                    Object.keys(this.lunchBreakMap[day]).forEach(day_hours => {
                        if (working_schedule[day_hours]) {
                            this.lunchBreakMap[day][day_hours] = working_schedule[day_hours];
                        }
                    });
                });
                Object.keys(this.whBreakMap).forEach(day => {
                    Object.keys(this.whBreakMap[day]).forEach(day_hours => {
                        if (working_schedule[day_hours]) {
                            this.whBreakMap[day][day_hours] = working_schedule[day_hours];
                        }
                    });
                });
            }
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async getData() {
        if (this.isUserKindAdvisor) {
            if (this.user && this.user.branch) {
                this.getSiteById(this.user.branch.id, 'sites');
            } else if (this.user && this.user.hub) {
                this.getSiteById(this.user.hub.id, 'hubs');
            }
        } else {
            this.getWorkers();
        }
    }

    async getUserData() {
        try {
            const { data } = await AuthWebApi.getUserDetails();
            let siteData = [];

            if (data.branch_rooms && data.branch_rooms.length) {
                siteData = data.branch_rooms.map(({ ...rest }) => ({ is_site: true, auth_user_id: this.user.id, ...rest }));
            }

            this.users = [...data.user_names, ...siteData];

            this.getMonthDatesFirstInitial(data.user_dates);
            this.updateDataFromServer(data, false);

            if (data.branch_working_schedule && data.branch_working_schedule.length) {
                const [branch_wh] = data.branch_working_schedule;

                Object.keys(this.lunchBreakMap).forEach(day => {
                    Object.keys(this.lunchBreakMap[day]).forEach(day_hours => {
                        if (branch_wh[day_hours]) {
                            this.lunchBreakMap[day][day_hours] = branch_wh[day_hours];
                        }
                    });
                });
                Object.keys(this.whBreakMap).forEach(day => {
                    Object.keys(this.whBreakMap[day]).forEach(day_hours => {
                        if (branch_wh[day_hours]) {
                            this.whBreakMap[day][day_hours] = branch_wh[day_hours];
                        }
                    });
                });
            }
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async created() {
        this.pageLoading = true;
        window.addEventListener('resize', this.handleResize);
        this.createDates();
        this.handleResize();
        // await this.getData();
        await this.getUserData();
        this.usersData(this.usersDataByKind);
        if (this.isMonthType) {
            await this.getCalendarData((this.date as Date));
        }
        if (this.isImaSanteAccount || this.isImaMedicalAccount) {
            this.userFilter = [this.user];
        }
        this.userIsAdmin();
        this.pageLoading = false;
        this.loading = false;
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }

    @Watch('isMenuOpen')
    handleIsMenuOpen(value: boolean) {
        if (value !== this.isFixedBg) {
            this.setFixedBgStatus(value);
        }
    }

    @Watch('isFixedBg')
    handleIsFixedBg(value: boolean) {
        if (!value) {
            this.isMenuOpen = value;
        }
    }

    @Watch('userFilterIds')
    userFilterIdsHandler() {
        this.arrowKey += 1;
    }
}
</script>

<style lang='sass'>
.h-height-auto-force
    height: auto !important
    min-height: auto !important

.b-calendar-page
    position: relative
    flex: 1
    height: 100%
    background-color: #fff

    &__burger
        position: absolute
        right: 0
        top: 6px
        z-index: 1002

    &__left-bar
        height: 100vh
        flex: 0 0 340px
        overflow: auto
        display: flex
        justify-content: center
        padding-top: 30px
        position: relative

    &__calendar_wrapper
        width: 280px
        min-width: 280px
        position: relative

        .mx-datepicker-popup
            z-index: 10 !important

    .multiselect__tags
        padding: 0!important
        max-height: 30px!important
        min-height: 30px!important

    &__body
        padding-bottom: 0
        width: 100%

    &__title
        font-size: 20px

    &__today-button
        width: 83px
        height: 28px

    &__show-button
        position: fixed
        bottom: 0
        width: 100%
        background: #fff

    &__logo
        margin-bottom: 25px
        padding-left: 10px
        padding-right: 10px
        display: flex
        justify-content: space-between
        align-items: center
        // temp
        filter: grayscale(100%)
        opacity: 0.4

.fw-horizontal-scroll__inner
    height: calc(100vh - 80px)

.fw-horizontal-scroll__content
    height: 100%!important

.b-user-list__main--scroll
    &-list
        align-items: flex-start
        height: 170px

.b-calendar-page__corner-fix
    display: block
    margin-top: -95px

    .fw-horizontal-scroll__shadow--left
        left: 60px
        z-index: 5

    &:after
        content: ''
        top: 0
        left: 0
        width: 65px
        height: 40px
        z-index: 5
        background-color: #fff
        position: absolute

@include media('<tablet')
    .b-appointment
        &-left-bar
            overflow: scroll
            position: fixed
            top: 0
            width: 100%
            height: 100%
            z-index: 11

    .b-calendar-page
        &__header
            flex-flow: column nowrap
            margin: 0
            padding: 5px 0

        &__title
            font-size: 16px

.b-global-loading
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 0
    left: 0
    background-color: rgba(255,255,255,1)
    z-index: 30

    &--transparent
        background-color: transparent
</style>
